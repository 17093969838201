<template>
  <div>
    <data-list :category="category" detailRouter="/policy-detail"></data-list>
  </div>
</template>

<script>
import dataList from '@/components/DataList'
export default {
  name: 'PolicyList',

  components: { dataList },

  data () {
    return {
      category: 'Policy'
    }
  }
}
</script>

<style scoped>
</style>
